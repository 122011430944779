import en from './locales/en.json'
import de from './locales/de.json'
import es from './locales/es.json'

export default defineI18nConfig(() => ({
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  availableLocales: ['en', 'de', 'es'],
  messages: {
    en,
    de,
    es,
  },
}))
